<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <base-header
      :title="$t('app.sucursales.name')"
      link="Dashboard"
    />
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <base-material-card color="primary">
          <template v-slot:heading>
            <v-row>
              <v-col class="flex-grow-1 flex-grow-0">
                <div class="display-2 font-weight-light">
                  {{ $t('app.sucursales.title') }}
                </div>

                <div class="subtitle-1 font-weight-light">
                  {{ $t('app.sucursales.subtitle') }}
                </div>
              </v-col>
              <v-col class="d-flex flex-row-reverse align-center flex-grow-0 flex-shrink-0">
                <v-btn
                  dark
                  @click="openItemAdd"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-plus-circle
                  </v-icon>

                  {{ $t('app.buttons.add') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <v-card-title>
            <v-text-field
              v-model="filters.selected.query"
              :label="$t('app.buttons.search')"
              rounded
              outlined
              dense
              append-icon="mdi-magnify"
              clearable
            />
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="10"
            :search="filters.selected.query"
          >
            <template v-slot:item.actions="{ item }">
              <v-col class="d-flex justify-end">
                <v-btn
                  icon
                  @click="openItemDetail(item)"
                >
                  <v-icon>
                    mdi-eye
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemEdit(item)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemDelete(item)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-col>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>

    <sucursales-add />
    <sucursales-edit />
    <sucursales-detail />
    <sucursales-delete />
  </v-container>
</template>

<script>
import {mapMutations} from 'vuex'

  export default {
    name: 'Sucursales',
    components: {
      SucursalesAdd: () => import('./SucursalesAdd'),
      SucursalesEdit: () => import('./SucursalesEdit'),
      SucursalesDetail: () => import('./SucursalesDetail'),
      SucursalesDelete: () => import('./SucursalesDelete'),
    },
    data () {
      return {
        headers: [
          {
            text: this.$t('app.headers.id'),
            value: 'id',
          },
          {
            text: this.$t('app.headers.name'),
            value: 'nombre',
          },
          {
            text: this.$t('app.headers.code'),
            value: 'code',
          },
          {
            text: this.$t('app.headers.street'),
            value: 'calle',
          },
          {
            text: this.$t('app.headers.suburb'),
            value: 'colonia',
          },
          {
            text: this.$t('app.headers.state'),
            value: 'estado.nombre',
          },
          {
            text: this.$t('app.headers.municipality'),
            value: 'municipio.nombre',
          },
          {
            text: this.$t('app.headers.location'),
            value: 'localidad.nombre',
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
          },
        ],
        items: [],
        filters: {
          selected: {
            query: '',
          },
        },
      }
    },
    mounted () {
      this.init()

      EventBus.$on('reload-items', () => {
        this.init()
      })
    },
    methods: {
      ...mapMutations({
        setAppData: 'SET_APP_DATA',
      }),
      async init () {
        this.toggleLoader()

        await this.$http.get(route('v1/branch'))
          .then(response => {
            if (response.body.code === 200) {
              this.items = response.body.data
              this.setAppData({
                sucursales: this.items,
              })
            } else {
              this.processError(response)
            }
          }, error => {
            this.processError(error)
          })

        this.toggleLoader()
      },
      openItemAdd () {
        EventBus.$emit('sucursales-add')
      },
      openItemEdit (item) {
        EventBus.$emit('sucursales-edit', item)
      },
      openItemDetail (item) {
        EventBus.$emit('sucursales-detail', item)
      },
      openItemDelete (item) {
        EventBus.$emit('sucursales-delete', item)
      },
    },
  }
</script>

<style scoped>

</style>
